import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8e03ff44 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _24aa4359 = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _12554962 = () => interopDefault(import('../pages/beranda/index.vue' /* webpackChunkName: "pages/beranda/index" */))
const _c5588198 = () => interopDefault(import('../pages/berita/index.vue' /* webpackChunkName: "pages/berita/index" */))
const _5dbf072a = () => interopDefault(import('../pages/display/index.vue' /* webpackChunkName: "pages/display/index" */))
const _3042e317 = () => interopDefault(import('../pages/galeri/index.vue' /* webpackChunkName: "pages/galeri/index" */))
const _8cbfcb78 = () => interopDefault(import('../pages/kebijakan-privasi/index.vue' /* webpackChunkName: "pages/kebijakan-privasi/index" */))
const _04f08921 = () => interopDefault(import('../pages/kontak/index.vue' /* webpackChunkName: "pages/kontak/index" */))
const _bf8eed32 = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _295cbad8 = () => interopDefault(import('../pages/pengumuman/index.vue' /* webpackChunkName: "pages/pengumuman/index" */))
const _bde34c86 = () => interopDefault(import('../pages/potensi/index.vue' /* webpackChunkName: "pages/potensi/index" */))
const _23816989 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _7016f628 = () => interopDefault(import('../pages/tentang/index.vue' /* webpackChunkName: "pages/tentang/index" */))
const _d9755f38 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _63edc054 = () => interopDefault(import('../pages/visi/index.vue' /* webpackChunkName: "pages/visi/index" */))
const _4c90a6c2 = () => interopDefault(import('../pages/profil/geografis.vue' /* webpackChunkName: "pages/profil/geografis" */))
const _4a1861c7 = () => interopDefault(import('../pages/profil/lambang.vue' /* webpackChunkName: "pages/profil/lambang" */))
const _75f99667 = () => interopDefault(import('../pages/profil/sejarah.vue' /* webpackChunkName: "pages/profil/sejarah" */))
const _387cb72d = () => interopDefault(import('../pages/profil/visi-misi.vue' /* webpackChunkName: "pages/profil/visi-misi" */))
const _d595d110 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c8c5c428 = () => interopDefault(import('../pages/berita/_slug.vue' /* webpackChunkName: "pages/berita/_slug" */))
const _2e8c41cf = () => interopDefault(import('../pages/galeri/_slug.vue' /* webpackChunkName: "pages/galeri/_slug" */))
const _5d210554 = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _27a61990 = () => interopDefault(import('../pages/pengumuman/_slug.vue' /* webpackChunkName: "pages/pengumuman/_slug" */))
const _c1508f16 = () => interopDefault(import('../pages/potensi/_slug.vue' /* webpackChunkName: "pages/potensi/_slug" */))
const _d90313a0 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _8e03ff44,
    name: "404"
  }, {
    path: "/agenda",
    component: _24aa4359,
    name: "agenda"
  }, {
    path: "/beranda",
    component: _12554962,
    name: "beranda"
  }, {
    path: "/berita",
    component: _c5588198,
    name: "berita"
  }, {
    path: "/display",
    component: _5dbf072a,
    name: "display"
  }, {
    path: "/galeri",
    component: _3042e317,
    name: "galeri"
  }, {
    path: "/kebijakan-privasi",
    component: _8cbfcb78,
    name: "kebijakan-privasi"
  }, {
    path: "/kontak",
    component: _04f08921,
    name: "kontak"
  }, {
    path: "/layanan",
    component: _bf8eed32,
    name: "layanan"
  }, {
    path: "/pengumuman",
    component: _295cbad8,
    name: "pengumuman"
  }, {
    path: "/potensi",
    component: _bde34c86,
    name: "potensi"
  }, {
    path: "/profil",
    component: _23816989,
    name: "profil"
  }, {
    path: "/tentang",
    component: _7016f628,
    name: "tentang"
  }, {
    path: "/video",
    component: _d9755f38,
    name: "video"
  }, {
    path: "/visi",
    component: _63edc054,
    name: "visi"
  }, {
    path: "/profil/geografis",
    component: _4c90a6c2,
    name: "profil-geografis"
  }, {
    path: "/profil/lambang",
    component: _4a1861c7,
    name: "profil-lambang"
  }, {
    path: "/profil/sejarah",
    component: _75f99667,
    name: "profil-sejarah"
  }, {
    path: "/profil/visi-misi",
    component: _387cb72d,
    name: "profil-visi-misi"
  }, {
    path: "/",
    component: _d595d110,
    name: "index"
  }, {
    path: "/berita/:slug",
    component: _c8c5c428,
    name: "berita-slug"
  }, {
    path: "/galeri/:slug",
    component: _2e8c41cf,
    name: "galeri-slug"
  }, {
    path: "/page/:slug?",
    component: _5d210554,
    name: "page-slug"
  }, {
    path: "/pengumuman/:slug",
    component: _27a61990,
    name: "pengumuman-slug"
  }, {
    path: "/potensi/:slug",
    component: _c1508f16,
    name: "potensi-slug"
  }, {
    path: "/:slug",
    component: _d90313a0,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
