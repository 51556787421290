const state = () => ({
  // unit_id: '0',
  // unit_name: 'Portal Kabupaten Klaten',
  unit: {
    id: '0',
    name: 'Portal Kabupaten Klaten'
  }
})

const mutations = {
  setUnit (state, unit) {
    state.unit = unit
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
